.featuresList {
  list-style: none;
  padding-left: 1em;
}

.featuresList li:before {
  content: "\2713\0020";
  color: #28a745;
}

.featuresList li.warning:before {
  content: "\26A0\0020";
  color: #ff9800;
}
