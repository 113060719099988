ul.result {
  list-style: none;
  margin-left: 0;
  padding-left: 1.2em;
}

ul.result .error:before {
  font-family: FontAwesome;
  content: "\f057";
  float: left;
  width: 1.5em;
  color: red;
}

ul.result .warning:before {
  font-family: FontAwesome;
  content: "\f06a";
  float: left;
  width: 1.5em;
  color: orange;
}

ul.result .success:before {
  font-family: FontAwesome;
  content: "\f058";
  float: left;
  width: 1.5em;
  color: green;
}

.error:before {
  font-family: FontAwesome;
  content: "\f057";
  float: left;
  width: 1.5em;
  color: red;
}

.warning:before {
  font-family: FontAwesome;
  content: "\f06a";
  float: left;
  width: 1.5em;
  color: orange;
}

.success:before {
  font-family: FontAwesome;
  content: "\f058";
  float: left;
  width: 1.5em;
  color: green;
}

